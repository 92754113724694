.back
    position: fixed
    top: 0
    left: 0
    width: 100%
    background: #fff
    z-index: 15

    &__link
        display: flex
        align-items: center
        justify-content: center
        text-align: center
        height: 42px
        padding: 4px 32px
        font-weight: 500
        color: $black
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9.93896C0 10.3208 0.145996 10.6465 0.449219 10.9385L9.20898 19.5073C9.44482 19.7544 9.75928 19.8779 10.1187 19.8779C10.8486 19.8779 11.4214 19.3164 11.4214 18.5752C11.4214 18.2158 11.2754 17.8901 11.0283 17.6431L3.1333 9.93896L11.0283 2.23486C11.2754 1.97656 11.4214 1.65088 11.4214 1.2915C11.4214 0.561523 10.8486 0 10.1187 0C9.75928 0 9.44482 0.123535 9.20898 0.370605L0.449219 8.93945C0.145996 9.23145 0.0112305 9.55713 0 9.93896Z' fill='%239A9FA8'/%3E%3C/svg%3E%0A")
        background-position: left 8px center
        background-repeat: no-repeat
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        transition: color .5s ease
