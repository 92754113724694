.input
    display: block
    width: 100%
    height: 52px
    border-radius: 4px
    background: none
    border: 1px solid #d9dadd
    box-shadow: none
    font-size: 14px
    line-height: 1.2
    color: $black
    padding: 4px 12px
    font-weight: 400
    outline: none
    appearance: none
    transition: all .5s ease

    &:hover,
    &:focus
        border-color: #9a9fa8

    &::placeholder
        color: #9a9fa8
        font-weight: 400

    &--pr-48
        padding-right: 48px



.input-group
    position: relative

    &__show-pass
        position: absolute
        top: 50%
        right: 8px
        display: flex
        align-items: center
        justify-content: center
        width: 36px
        height: 36px
        margin-top: -18px
        border-radius: 50%
        color: #e6e6e6

        &:hover
            outline: none

        &:active
            outline: none

        &:focus
            outline: none

        &-icon
            fill: currentColor
            transition: color .5s ease

        &.is-active
            color: #636a77



.input-error
    position: absolute
    bottom: -20px
    left: 0
    display: block
    width: 100%
    color: $red
    font-size: 12px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    opacity: 0
    visibility: hidden
    transition: opacity .5s ease
