.device
    background: #fafafd
    //padding: 60px 32px 32px
    padding: 0 32px 32px

    &__picture
        display: flex
        align-items: center
        justify-content: center
        //margin-bottom: 52px

    &__img
        max-width: 100%
        height: auto

    &__table
        max-width: 240px
        width: 100%
        margin: 0 auto

        td
            width: 50%

    &-status
        padding: 24px 32px 148px
        text-align: center

        &__title
            font-size: 20px
            font-weight: 500
            margin: 0 0 8px

        &__descr
            color: #636a77
            margin-bottom: 56px

            strong
                color: $black

        &__info
            font-size: 12px
            font-weight: 500
            color: #636a77
            margin: 0 0 24px

        &__button
            display: inline-flex
            width: auto

        &-error
            display: inline-flex
            align-items: center
            text-align: left
            margin-bottom: 36px

            &__text
                margin: 0 16px

            &__title
                font-size: 16px
                margin: 0 0 4px

            &__descr
                font-size: 12px
                color: #636a77
                margin: 0
