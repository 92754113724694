.login
    padding: 32px
    margin: auto 0

    &__logo
        text-align: center
        margin-bottom: 56px

    &__title
        font-size: 30px
        line-height: 1.27
        margin-bottom: 40px
        display: flex
        justify-content: space-between

    &-form

        &__group
            position: relative
            margin-bottom: 24px

            &.has-error

                .input
                    border-color: $red

                .input-error
                    opacity: 1
                    visibility: visible

        &__support
            text-align: right
            margin-bottom: 40px

        &__link
            color: $dark-blue
            font-weight: 500
            transition: color .5s ease

        &__or
            position: relative
            text-align: center
            margin: 0 26px 24px

            &::before
                position: absolute
                top: 50%
                left: 0
                width: 100%
                height: 1px
                background: #9a9fa8
                content: ""

            &-text
                display: inline-flex
                position: relative
                font-size: 14px
                font-weight: 500
                color: #9a9fa8
                padding: 0 10px
                background: #fff

