// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap")

@font-face
    font-family: "Inter"
    font-style: normal
    font-weight: 400
    font-display: swap
    src: local("Poppins Regular"), url("../fonts/inter-v12-latin-regular.woff2") format("woff2"), url("../fonts/inter-v12-latin-regular.woff") format("woff")

@font-face
    font-family: "Inter"
    font-style: normal
    font-weight: 500
    font-display: swap
    src: local("Poppins Medium"), url("../fonts/inter-v12-latin-500.woff2") format("woff2"), url("../fonts/inter-v12-latin-500.woff") format("woff")

@font-face
    font-family: "Inter"
    font-style: normal
    font-weight: 600
    font-display: swap
    src: local("Poppins Semibold"), url("../fonts/inter-v12-latin-600.woff2") format("woff2"), url("../fonts/inter-v12-latin-600.woff") format("woff")
