.lang

    &__link
        display: flex
        align-items: center
        color: $black
        padding: 12px 0
        transition: all .5s ease

    &__title
        font-size: 16px
        font-weight: 600
        margin: 0 10px

    &__arrow
        margin-left: auto

[dir="rtl"] .lang
    &__arrow
        margin-left: 0
        margin-right: auto
