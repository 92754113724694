// Fonts
$font: "Inter", sans-serif

$bg: #fff

// Colors
$black: #273142
$blue: #367fd3
$dark-blue: #0749b1
$green: #20ba68
$grey: #eaebef
$red: #ff3b30
$orange: #ff7a00


$yellow: #febb02
$brown: #492a15
$purple: #730147
$pink: #b60571
$light-red: #f31965
$dark-red: #cc0047
$light-blue: #3381a2
$light: #fde1c4
$gradient: linear-gradient(234.4deg, #96caf8, #0e94ff, #96caf8)



$mw-1170: "max-width: 1169.98px"
$mw-992: "max-width: 991.98px"
$mw-768: "max-width: 767.98px"
$mw-576: "max-width: 575.98px"
