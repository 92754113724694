.popup
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100vh
    background: rgba(0, 0, 0, .8)
    z-index: 10

    &__inner
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        width: 100%
        padding: 64px 32px
        overflow-y: auto

    &-item
        border-radius: 12px
        max-width: 270px
        width: 100%
        background: #fafafd

        &__pic
            display: flex
            align-items: center
            justify-content: center
            width: 44px
            height: 44px
            margin-bottom: 12px

        &__icon

            &--animated
                animation: progress 2s infinite linear
                fill: #454d5c

            @keyframes progress
                0%
                    transform: rotate(0)

                100%
                    transform: rotate(360deg)


        &__title
            font-size: 20px
            margin: 0

        &__descr
            font-size: 14px
            font-weight: 500
            margin: 6px 0 0

        &__text
            text-align: center
            padding: 16px

            &-title
                font-size: 16px
                margin: 0 0 2px

            &-descr
                font-size: 14px
                margin: 0

        &__buttons
            border-top: .5px solid #8e8e91
            display: flex

        &__button
            height: 44px
            flex: 1
            border-right: .5px solid #8e8e91
            font-weight: 500
            color: $blue

            &:last-child
                border-right-color: transparent
                font-weight: 500

        &--center
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            text-align: center
            height: 220px
            padding: 12px

    &--light
        background: rgba(28, 28, 30, .6)
        z-index: 20
