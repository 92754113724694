*,
*::before,
*::after
    box-sizing: border-box

html
    position: relative
    min-height: 100%

::selection
    background: $blue
    color: #fff

body
    font-size: 16px
    line-height: 1.5
    font-family: $font
    font-weight: 400
    color: $black
    background: #f6f6f6

h1,
h2,
h3,
h4,
h5,
h6
    font-weight: 600
    margin: 0

a
    text-decoration: none

    &:hover,
    &:active
        color: $red
        text-decoration: none

    &:focus
        outline: none
        text-decoration: none

.splash-screen
    display: flex
    align-items: center
    justify-content: center
    padding: 32px
    width: 100%
    max-width: 480px
    margin: 0 auto
    min-height: 100vh
    background: $bg


.main
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    max-width: 480px
    margin: 0 auto
    min-height: 100vh
    background: $bg


.logo

    &__img
        max-width: 100%
        height: auto
