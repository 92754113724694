.bottom-menu
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    border-top: 1px solid #d3d3d3
    background: #fbfbfb
    z-index: 10

    &__list
        display: flex
        list-style: none
        padding: 0
        margin: 0

    &__item
        width: 100%

    &__link
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        padding: 8px 8px 24px
        color: $black
        font-size: 10px
        transition: color .5s ease

        &.is-active
            color: $red

    &__icon
        fill: currentColor
        margin-bottom: 8px


