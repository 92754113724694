.progress-ring
    position: relative
    text-align: center
    color: $blue
    margin-bottom: 5px

    &__icon
        position: absolute
        top: 50%
        left: 50%
        transform: translateX(-50%) translateY(-50%)
        fill: currentColor

    &__svg
        position: relative
        width: 172px
        height: 172px
        z-index: 10
        transform: rotate(-90deg)

    &__circle
        width: 100%
        height: 100%
        fill: none
        stroke: #e5e6e9
        stroke-width: 8
        stroke-linecap: round
        transition: stroke-dashoffset .35s

        &-fill
            // stroke-dasharray: 169
            // stroke-dashoffset: calc(169 - (169 * 20) / 100)
            stroke: currentColor

    &--green
        color: $green

    &--red
        color: #e74b53
