.bind
    padding: 60px 52px 124px
    margin: auto 0

    &__qr
        width: 184px
        height: 184px
        margin: 0 auto 40px

        &-img
            max-width: 100%
            height: auto

    &__top-title
        font-size: 30px
        margin: 0 0 56px

    &__title
        font-size: 24px
        margin: 0 0 40px
        text-align: center

        &--mb-24
            margin-bottom: 24px

    &__descr
        text-align: center
        font-size: 20px
        color: #636a77
        margin: 0 0 42px

        strong
            color: $black
            font-weight: 600
