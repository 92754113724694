.languages
    padding: 60px 16px 148px

    &__list
        list-style: none
        padding: 0
        margin: 0 -16px

    &__item
        margin-bottom: 16px

        &:last-child
            margin-bottom: 0

    &__link
        position: relative
        display: block
        padding: 8px 40px 8px 16px
        color: $black
        font-weight: 500
        transition: color .5s ease
        -webkit-tap-highlight-color: rgba(155, 156, 157, .2)

        &::after
            position: absolute
            right: 16px
            top: 50%
            width: 24px
            height: 24px
            margin-right: -12px
            margin-top: -12px
            background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3026 0H13.0653C12.8918 0 12.7272 0.0854236 12.621 0.231593L5.32292 10.1464L1.82347 5.39118C1.77053 5.3191 1.70306 5.26081 1.62611 5.2207C1.54916 5.18059 1.46474 5.1597 1.37918 5.15959H0.141893C0.0232974 5.15959 -0.0421957 5.30576 0.0303777 5.40447L4.87863 11.9916C5.1052 12.2991 5.54064 12.2991 5.76898 11.9916L14.4141 0.242983C14.4866 0.146169 14.4211 0 14.3026 0Z' fill='%23FF3B30'/%3E%3C/svg%3E%0A")
            background-position: center center
            background-repeat: no-repeat
            content: ""
            opacity: 0
            visibility: hidden

        &.is-active
            color: $red

            &::after
                opacity: 1
                visibility: visible
