.account
    display: flex
    flex-direction: column
    flex: 1
    padding: 32px 16px 108px

    &__title
        font-size: 38px
        margin: 0 0 40px

    &-item
        display: flex
        align-items: center
        margin-bottom: 40px

        &__text
            margin: 0 16px

        &__pic
            display: flex
            align-items: center
            justify-content: center
            width: 56px
            height: 56px
            min-width: 56px
            background: #eaebef
            border-radius: 50%

        &__icon
            fill: #9a9fa8

        &__name
            font-size: 20px
            margin: 0 0 4px

        &__phone
            font-weight: 500
            color: #636a77
            margin: 0

    &__logout
        margin-top: auto
