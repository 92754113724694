.success
    padding: 60px 32px 148px
    margin: auto 0

    &__inner
        text-align: center

    &__icon
        margin-bottom: 24px

    &__title
        font-size: 38px
        margin: 0 0 34px
        line-height: 1.2

    &__descr
        color: #636a77
        margin: 0
