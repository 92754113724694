.status
    margin: auto 0
    padding: 60px 32px 148px

    &__qr
        width: 184px
        height: 184px
        margin: 0 auto 24px

        &-img
            max-width: 100%
            height: auto

    &__title
        color: #9a9fa8
        font-size: 20px
        margin: 0
        text-align: center

        &--green
            color: $green

        &--red
            color: #e74b53

    &__loading
        margin-bottom: 24px
        text-align: center

        &-icon
            fill: $blue
            animation: progress 2s infinite linear

            @keyframes progress
                0%
                    transform: rotate(0)

                100%
                    transform: rotate(360deg)
