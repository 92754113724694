.table
    width: 100%

    tbody
        tr
            td
                font-size: 14px
                color: #636a77
                padding: 5px 5px 5px 0

                &:last-child
                    font-weight: 600
                    color: $black
