.qr-scanning
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100vh
    z-index: 10
    border-width: calc(50vh - min(50vh, 50vw) + 20px) calc(50vw - min(50vh, 50vw) + 20px)
    border-style: solid
    border-color: rgba(0, 0, 0, .8)

    &__layout
        height: 100vh

        div
            width: auto
            height: 100%
            padding-top: 0

            video
                transform: translateX(-50%)

    &__code
        position: relative
        width: 100%
        height: 100%
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 216 216' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.97235 26.3662L5.97235 6.08451H25.8802V0H5.97235H1.90735e-06H0V26.3662H5.97235Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M210.028 189.634L210.028 209.915L190.12 209.915L190.12 216L210.028 216L216 216L216 216L216 189.634L210.028 189.634Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M190.12 6.0845L210.028 6.08451L210.028 26.3662L216 26.3662L216 6.08451L216 1.90735e-06L216 0L190.12 -1.1365e-06L190.12 6.0845Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.8802 209.915L5.97235 209.915L5.97235 189.634L3.40949e-06 189.634L7.86805e-07 209.915L0 216L0 216L25.8802 216L25.8802 209.915Z' fill='white'/%3E%3C/svg%3E%0A")
        background-position: center center
        background-repeat: no-repeat
        background-color: rgba(0, 0, 0, .2)

    &__button
        position: absolute
        bottom: -140px
        left: 50%
        display: flex
        align-items: center
        justify-content: center
        width: 72px
        height: 72px
        margin-left: -36px
        background: #636a77
        color: #fff
        border-radius: 50%
        z-index: 15

        &-icon
            fill: currentColor
