.info
    padding: 60px 32px 148px

    &__title
        font-size: 24px
        margin: 0 0 20px

    &__table-wrap
        border: 1px solid #e5e6e9
        padding: 3px 10px
        border-radius: 4px
        margin-bottom: 32px

    &-item
        border: 1px solid #e5e6e9
        padding: 8px 12px 12px
        border-radius: 4px
        margin-bottom: 20px

        &:last-child
            margin-bottom: 0

        &__header
            display: flex
            align-items: center
            margin-bottom: 16px

        &__title
            font-size: 24px
            font-weight: 600
            margin: 0 10px 0 0
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        &__number
            font-size: 20px
            font-weight: 500
            color: #454d5c
            margin: 0 10px 0 0
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        &__status
            display: flex
            align-items: center
            justify-content: center
            width: 63px
            min-width: 63px
            height: 28px
            margin-left: auto
            font-size: 12px
            font-weight: 400
            padding: 4px
            background: $green
            border-radius: 4px
            color: #fff

            &--black
                background: #636a77

        &__row
            display: flex
            margin: 0 -4px
            justify-content: space-between

        &__col-1
            padding: 0 4px
            max-width: 30%
            flex: 0 0 30%

        &__col-2
            padding: 0 4px
            max-width: 45%
            flex: 0 0 45%

        &__col-3
            padding: 0 4px
            max-width: 25%
            flex: 0 0 25%
            display: flex
            justify-content: flex-end

        &__sub-title
            font-size: 12px
            margin: 0 0 4px

        &__text
            color: #454d5c
            margin: 0

        &__qr-button
            display: flex
            align-items: center
            justify-content: center
            width: 48px
            height: 48px
            background: #fafafd
            border-radius: 4px
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .12)

[dir="rtl"] .info
    &-item
        &__status
            margin-left: 0
            margin-right: auto

