.password
    padding: 60px 32px
    margin: auto 0

    &--h-100
        display: flex
        flex-direction: column
        flex: 1

        .password__title
            margin-top: 15vh

    &__title
        font-size: 30px
        margin-bottom: 14px

    &__descr
        margin-bottom: 46px
        color: #454d5c

    &__back-button
        margin-top: auto
