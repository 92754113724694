.button
    position: relative
    display: flex
    align-items: center
    justify-content: center
    box-sizing: border-box
    -webkit-tap-highlight-color: rgba(7, 73, 177, .5)
    outline: none
    border: 0
    margin: 0
    width: 100%
    cursor: pointer
    user-select: none
    vertical-align: middle
    appearance: none
    text-decoration: none
    font-weight: 500
    font-size: 16px
    line-height: 1
    letter-spacing: 0
    height: 56px
    padding: 4px 16px
    border-radius: 4px
    color: #fff
    background: $blue
    box-shadow: 0 6px 16px rgba(0, 0, 0, .06), 0 2px 2px rgba(0, 0, 0, .04)
    transition: all .5s ease

    &:hover
        outline: none
        background: $blue

    &:active
        outline: none
        background: $blue

    &:focus
        outline: none
        background: $blue

    &:disabled,
    &:disabled:hover
        background: #d9dadd
        color: #9a9fa8
        cursor: not-allowed
        opacity: .35

    &--mt-60
        margin-top: 60px

    &--green
        background: $green

        &:hover,
        &:active,
        &:focus
            background: $green

        &:disabled
            background: #d9dadd
            color: #9a9fa8

    &--red
        background: $red

        &:hover,
        &:active,
        &:focus
            background: $red

        &:disabled
            background: #d9dadd
            color: #9a9fa8

    &--grey
        background: #eaebef
        color: #636a77

        &:hover,
        &:active,
        &:focus
            background: #eaebef
            color: #636a77

    &--outline-blue
        height: 48px
        font-size: 14px
        color: $blue
        border: 1px solid $blue
        background: none
        box-shadow: none

        &:hover,
        &:active,
        &:focus
            background: none

        &:disabled
            background: none
            color: $blue

    &--outline
        border: 1px solid $black
        background: none
        color: $black
        box-shadow: none

        &:hover,
        &:active,
        &:focus
            background: none
            color: $black
